<template>
	<b-card class="a-card-menu p-4" :class="hasBreadcumbs ? 'mt-5' : ''">
		<h3 class="w-color-black fw-bold mb-4">{{ welcomeText }}</h3>
		<b-list-group flush>
			<template v-for="(subpage, key, i) in profileLinks">
				<b-list-group-item
					v-if="subpage.url"
					:key="key" :to="subpage.url"
					class="d-flex"
					:class="(i == Object.keys(profileLinks).length - 1) ? 'pt-3' : 'py-3'"
				>
					<component :is="subpage.icon" class="icon" :class="`w-color-${subpage.iconColor}`"></component>
					{{ subpage.title }} 
				</b-list-group-item>
			</template>

			<b-list-group-item to="/" class="logout mt-5" @click.prevent="logout">
				<svg-logout-icon class="icon" />{{ $t('Odhlásiť') }}
			</b-list-group-item>
		</b-list-group>
	</b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	components: {
		'svg-logout-icon': () => import('@/plugins/appzmudri/_theme/icon/logout.svg?inline')
	},
	data() {
		return {
			hasBreadcumbs: false
		}
	},
	computed: {
		...mapGetters('wAuth', [
			'user'
		]),
		isAdmin() {
			return this.user.profile.type == 'teacher'
		},
		userType() {
			return this.user && this.user.profile && this.user.profile.type
		},
		welcomeText() {
			return this.userType == 'teacher' ? this.$t('Vitajte v profile') : this.$t('Vitaj v profile')
		},
		profileLinks() {
			return this.CONFIG.profile.profileLinks[this.userType]
		}
	},
	methods: {
		async logout() {
			this.$wEvent.userLog('user.logout', {timestamp: new Date()})
			await this.$store.dispatch('wAuth/logout')
			localStorage.removeItem('LS_LAST_ROUTE')
		}
	},
	watch: {
		$route: {
			immediate: true,
			deep: true,
			handler: function() {
				//for now only triedy route has breacrumbs
				this.hasBreadcumbs = this.$route.path.includes('/triedy')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.a-card-menu {
	.router-link-active:not(.logout) {
		font-weight: bold;
	}

	a:hover {
		color: var(--ion-color-primary);
		text-decoration: none;
		cursor: pointer;
	}

	.icon {
		width: 20px;
		height: 20px;
		margin-right: 1.25rem;
		overflow: visible;
	}
}
</style>